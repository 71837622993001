<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h3 class="pb-3">Notifications Center</h3>
            <div class="classic-tabs" v-if="notifications.length">
            <mdb-tabs
              :active="0"
              card
              color="orange"
              :links="tabsContent"
              ref="thetabs"
            >
              <template v-for="(cat, index) in tabsContent" :slot="cat.slot">
                <div v-bind:key="`tmpl_${index}`">
                  <h3>{{cat.text}}</h3>
                  <mdb-tbl>
                    <mdb-tbl-body>
                      <tr>
                        <td colspan="3">
                          <mdb-input
                            class="my-2"
                            type="checkbox"
                            :id="`chkall_${cat.cat}`"
                            v-model="checkboxbinding[cat.cat]"
                            v-on:change="togglechk"
                          />
                        </td>
                        <td>
                          <mdb-btn color="danger" :disabled="deleteBtnEnabledFor(cat.cat)" v-on:click="deleteNotifsBatch(cat.cat)">Delete all checked</mdb-btn>
                        </td>
                      </tr>
                      <tr v-for="(notif, indexn) in getNotifsForCat(cat.cat)" v-bind:key="indexn">
                        <td><mdb-input type="checkbox" v-model="notif.ischecked" :id="`chk_${indexn}_${cat.cat}`" /></td>
                        <td>{{notif.title}}</td>
                        <td>{{getTimeAgo(notif.created_at)}}</td>
                        <td><mdb-btn v-on:click="currentnotif=notif" size="sm">View</mdb-btn></td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </div>
              </template>
            </mdb-tabs>
            </div>
            <h3 v-else>No notifications</h3>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal v-if="currentnotif" :show="currentnotif!==null" info>
      <mdb-modal-header :close="false">{{currentnotif.title}}</mdb-modal-header>
      <mdb-modal-body>
        {{currentnotif.message}}
        <div v-if="currentnotif.url" class="w-100 text-center">
          <hr />
          <mdb-btn tag="a" target="_blank" :href="currentnotif.url" style="padding:.84rem 2.14rem;" color="secondary" size="sm">{{currentnotif.urltitle}}</mdb-btn>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer><mdb-btn outline="elegant" style="padding:.84rem 2.14rem;" v-on:click="closemodal()">Close</mdb-btn></mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbTabs,
  mdbTbl,
  mdbTblBody,
  mdbInput,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';

export default {
  name: 'documentationCenter',
  data() {
    return {
      notifications: [],
      currentnotif: null,
      isloading: true,
      error: null,
      currenttabfortoggle: '',
      checkboxbinding: {},
      iconsassoc: [
        {
          cat: 'PAXNOBIRTHDATE',
          icon: 'birthday-cake',
          label: 'Birthdate err.',
        },
        {
          cat: 'HOLASUNINVALIDFILENAME',
          icon: 'file-excel',
          label: 'Manifest err.',
        },
        {
          cat: 'OVERBOOKING',
          icon: 'infinity',
          label: 'Overbooking',
        },
        {
          cat: 'CREWCHANGEERROR',
          icon: 'users',
          label: 'Crew err.',
        },
        {
          cat: 'AIRCRAFTTAKEOFF',
          icon: 'plane-departure',
          label: 'Take-off',
        },
        {
          cat: 'AIRCRAFTLANDING',
          icon: 'plane-arrival',
          label: 'Landing',
        },
      ],
    };
  },
  computed: {
    tabsContent() {
      const toreturn = [];
      Object.values(this.notifications.reduce((rv, x) => {
        const rvtemp = rv || [];
        rvtemp[x.notificationkey] = rv[x.notificationkey] || { nb: 0, key: x.notificationkey };
        rvtemp[x.notificationkey].nb += 1;
        return rvtemp;
      }, {})).forEach((val) => {
        this.checkboxbinding[val.key] = false;
        toreturn.push({
          text: `${this.iconsassoc.filter((x) => x.cat === val.key).shift().label} (${val.nb})`,
          icon: this.iconsassoc.filter((x) => x.cat === val.key).shift().icon,
          cat: val.key,
          bigIcon: true,
          slot: `${val.key}_slot`,
        });
      });

      return toreturn;
    },
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbTabs,
    mdbTbl,
    mdbTblBody,
    mdbInput,
  },
  mounted() {
    this.loadAllNotifications();
  },
  methods: {
    loadAllNotifications() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/notification/list').then((response) => {
        self.notifications = response.data.map((x) => { const t = x; t.ischecked = false; return t; });
      });
    },
    getNotifsForCat(cat) {
      return this.notifications.filter((x) => x.notificationkey === cat);
    },
    getTimeAgo(datetime) {
      return Moment(datetime).fromNow();
    },
    closemodal() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/notification/set-as-read', { notifid: this.currentnotif.id }).then(() => {
        self.currentnotif = null;
        self.loadAllNotifications();
      });
    },
    togglechk(val) {
      const currentcat = this.$refs.thetabs.tabLinks[this.$refs.thetabs.activeTab].cat;
      this.notifications.map((x) => {
        const t = x;
        if (t.notificationkey === currentcat) {
          t.ischecked = val;
        }
        return t;
      });
    },
    deleteBtnEnabledFor(cat) {
      return !this.notifications.filter((x) => x.notificationkey === cat && x.ischecked).length > 0;
    },
    deleteNotifsBatch(cat) {
      const idstodelete = this.notifications.filter((x) => x.notificationkey === cat && x.ischecked).map((y) => y.id);
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/notification/set-as-read-batch', { ids: idstodelete }).then(() => {
        self.loadAllNotifications();
      });
    },
  },
};
</script>
